<template>
    <div>
        <video ref="videoPlayer" class="video-js vjs-default-skin"></video>
    </div>
</template>

<script>
    import videojs from 'video.js';
    import 'video.js/dist/video-js.css';
    import 'flv.js/dist/flv.js';

    export default {
        name: 'VideoPlayer',
        data() {
            return {
                player: null,
            };
        },
        mounted() {
            this.initVideoPlayer();
        },
        methods: {
            initVideoPlayer() {
                this.player = videojs(this.$refs.videoPlayer, {
                    // video.js options here
                    controls: true,
                    preload: 'auto',
                    fluid: true,
                    sources: [
                        {
                            src: 'rtmp://live.life-oasis.com/live/test', // 例如: rtmp://yourserver/live/stream
                            type: 'video/x-flv',
                        },
                    ],
                });

                this.player.ready(function() {
                    console.log('Player is ready');
                });
            },
        },
        beforeDestroy() {
            if (this.player) {
                this.player.dispose();
            }
        },
    };
</script>

<style>
    /* Add custom styles here */
    .video-js .vjs-tech {
        width: 100%;
        height: auto;
    }
</style>