<template>

    <video :id="videoId"
           class="pusher"
           controls
           autoplay
           style="width:100%;height:100%;" />

</template>

<script>
    import Srs from '@/assets/js/srs.sdk'
    export default {
        name: 'webrtcPusher',
        props: {
            videoId: {
                type: String,
                default: 'pusher'
            },
            url: {
                type: String,
                default: ''
            },
            isVideo: {
                type: Boolean,
                default: false
            }
        },
        data () {
            return {
                sdk: null,
            }
        },
        created () {
             this.url = 'webrtc://live.life-oasis.com/live/test'
        },
        mounted () {
            this.$watch('url', () => {
                console.log('url', this.url)
                if (this.url) {
                    this.start()
                } else {
                    // this.stop()
                }
            }, { immediate: true, deep: true })

        },
        methods: {
            start () {
                //1.定义播放器
                console.log(Srs);
                var play = document.getElementById(this.videoId);
                // 2.  调用srs.sdk.js的SrsRtcPublisherAsync方法，new一个对象（记得将方法暴漏出来）
                this.sdk = new Srs.SrsRtcPublisherAsync()

                // 3.  执行SrsRtcPublisherAsync中的publish方法进行推流（jq调用ajax改为原生ajax）
                //this.sdk.prepareShareStream();

                this.sdk.publish(this.url).then(session => {
                    //   推流成功
                    console.log('session', session)
                }).catch(reason => {
                    //   推流失败
                    // 3.1 执行close方法，关闭推流
                    this.sdk.close();
                    console.log('reason', reason)
                    // 3.2错误判断
                    if (reason.name === 'HttpsRequiredError') {
                        alert(`WebRTC推流必须是HTTPS或者localhost：` + reason.name + reason.message);
                    } else if (reason.name === 'NotFoundError') {
                        alert(`找不到麦克风和摄像头设备：getUserMedia` + reason.name + reason.message);
                    } else if (reason.name === 'NotAllowedError') {
                        alert(`你禁止了网页访问摄像头和麦克风：getUserMedia` + reason.name + reason.message);
                    } else if (reason.name === 'NotAllowedError') {
                        alert(`你禁止了网页访问摄像头和麦克风：getUserMedia` + reason.name + reason.message);
                    } else if (['AbortError', 'NotAllowedError', 'NotFoundError', 'NotReadableError', 'OverconstrainedError', 'SecurityError', 'TypeError'].includes(reason.name)) {
                        alert(`getUserMedia` + reason.name + reason.message);
                    } else {
                        alert(reason.name + reason.message);
                    }
                })
                //  4.打开播放器
                   new JSWebrtc.Player(this.url, { video: play, autoplay: true, });
                 const rtcPlayer = new Srs.SrsRtcPlayerAsync()
                 .play(this.url)
                  play.srcObject = rtcPlayer.stream

                if (this.isVideo) {
                     play.style.display = 'block'
                } else {
                     play.style.display = 'none'

                }
            },
            play () {
            }
        }
    }</script>