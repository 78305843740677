import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/index.vue'
import Video from '../views/video.vue'
import LiveHouse from '../views/liveHouse.vue'
import Live from '../views/live.vue'
import Live2 from '../views/live2.vue'
import WebRTC from '../views/webrtc.vue'
import Index2 from '../views/index2.vue'
import Test from '../views/test.vue'
import Head from '../views/head.vue'
import Five from '../views/five.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: Index
  },
  {
    path: '/video',
    name: 'video',
    component: Video
  },
  {
    path: '/test',
    name: 'test',
    component: Test
  }
  ,
  {
    path: '/head',
    name: 'head',
    component: Head
  },
  {
    path: '/live_house',
    name: 'live_house',
    component: LiveHouse
  },{
    path: '/live',
    name: 'live',
    component: Live
  },{
    path: '/five',
    name: 'five',
    component: Five
  },
  {
    path: '/signLive/live',
    component: Five,
    hidden: true,
    permissions: ['sign:liveMeeting:list'],
    children: [{
      path: 'startLive/:path',
      component: () => import('@/views/five'),
      name: 'AuthUser',
      meta: {
        title: '开始直播',
        activeMenu: '/signLive/live'
      }
    }]
  },
  {
    path: '/signLive/video',
    component: Video,
    hidden: true,
    permissions: ['sign:liveMeeting:list'],
    children: [{
      path: 'openVideo/:path',
      component: () => import('@/views/video'),
      name: 'AuthUser',
      meta: {
        title: '开始直播',
        activeMenu: '/signLive/video'
      }
    }]
  },
  {
    path: '/live2',
    name: 'live2',
    component: Live2
  }
  ,{
    path: '/webrtc',
    name: 'webrtc',
    component: WebRTC
  }
  ,{
    path: '/index2',
    name: 'index2',
    component: Index2
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
